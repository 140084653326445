import { useForm } from 'react-hook-form';
import { collection, addDoc, doc, updateDoc } from "firebase/firestore"; 
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spacer,
} from '@chakra-ui/react';
import { db } from 'firebaseConfig';
import { useClient } from '../../hooks/useClient';

interface ClientFormProps {
  onCloseModal: () => void;
  newData: (data: ClientType[]) => void;
  updateData: ClientType[];
}

export default function ClientForm(props: ClientFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ClientType>();
  const { getClients } = useClient();

  async function onSubmit(values: ClientType) {
    try {
      if(props.updateData === null){
        await addDoc(collection(db, 'client'), values);
      }else{      
        const updateClient = doc(db, "client", props.updateData[0].id);
        await updateDoc(updateClient, values);
      }
      props.onCloseModal();
      const newData: ClientType[] = await getClients();
      props.newData(newData);
    } catch (error) {
      console.error('Error adding data to Firebase:', error);
    }
  }
  const getUpdateDataValue = (field: keyof ClientType) => {
    return props.updateData !== null ? props.updateData[0][field] : '';
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.raison_sociale} mb={4}>
        <FormLabel htmlFor='raison_sociale'>Raison sociale</FormLabel>
        <Input
          id='raison_sociale'
          placeholder='Raison sociale'
          {...register('raison_sociale', {
            required: 'Champ obligatoire',
            minLength: { value: 4, message: 'La longueur minimum est de 4 caractères' },
          })}
          defaultValue={getUpdateDataValue('raison_sociale')}
        />
        <FormErrorMessage>
          {errors.raison_sociale && errors.raison_sociale.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.adresse} mb={4}>
        <FormLabel htmlFor='adresse'>Adresse</FormLabel>
        <Input
          id='adresse'
          placeholder='Adresse'
          {...register('adresse', {
            required: 'Champ obligatoire',
            minLength: { value: 4, message: 'La longueur minimum est de 4 caractères' },
          })}
          defaultValue={getUpdateDataValue('adresse')}
        />
        <FormErrorMessage>
          {errors.adresse && errors.adresse.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.cp} mb={4}>
        <FormLabel htmlFor='cp'>Code postal</FormLabel>
        <Input
          id='cp'
          placeholder='Code postal'
          {...register('cp', {
            required: 'Champ obligatoire',
            minLength: { value: 4, message: 'La longueur minimum est de 4 caractères' },
          })}
          defaultValue={getUpdateDataValue('cp')}
        />
        <FormErrorMessage>
          {errors.cp && errors.cp.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.ville} mb={4}>
        <FormLabel htmlFor='ville'>Ville</FormLabel>
        <Input
          id='ville'
          placeholder='Ville'
          {...register('ville', {
            required: 'Champ obligatoire',
            minLength: { value: 4, message: 'La longueur minimum est de 4 caractères' },
          })}
          defaultValue={getUpdateDataValue('ville')}
        />
        <FormErrorMessage>
          {errors.ville && errors.ville.message}
        </FormErrorMessage>
      </FormControl>

      <Button mt={4} colorScheme='teal' isLoading={isSubmitting} type='submit' mb={4}>
        {props.updateData !== null ? `Modifier le client` : `Ajouter le client`}
      </Button>
    </form>
  );
}
