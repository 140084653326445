import { useEffect, useState } from 'react';
import { useContact } from 'hooks/useContact';

interface ContactProps {
  id: string;
}

function MainContact(props: ContactProps): JSX.Element {
  const [contact, setContact] = useState('');
  const { getMainContact } = useContact();
  useEffect(() => {
    let isMounted = true; // Variable de suivi pour vérifier si le composant est monté
    const fetchData = async () => {
      const fetchedContact = await getMainContact(props.id);
      if (isMounted) {
        setContact(fetchedContact);
      }
    };
    fetchData();
    return () => {
      isMounted = false; // Définir la variable isMounted sur false lors du démontage du composant
    };
  }, [props.id]);

  return <span>{contact}</span>;
}

export default MainContact;