import { Route, Redirect, RouteProps } from "react-router-dom";
import { useUserAuth } from "./auth";

interface ProtectedRouteProps extends RouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children, ...rest }: ProtectedRouteProps) => {
  const { user } = useUserAuth();

  const renderRoute = (props: any) => {
    if (!user) {
      console.log("pas loggé");
      
      return <Redirect to="/auth" />;
    }
    console.log("loggé");
    return children;
  };
  return <Route {...rest} render={renderRoute} />;
};

export default ProtectedRoute;
