import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart, MdOutlineInsertDriveFile } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import DataTables from 'views/admin/dataTables';
import Clients from 'views/admin/clients';
import Forfaits from 'views/admin/forfaits';
import Contacts from 'views/admin/contacts';
import Interventions from 'views/admin/interventions';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
	{
		name: 'Main Dashboard',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	/*{
		name: 'Data Tables',
		layout: '/admin',
		icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
		path: '/data-tables',
		component: DataTables
	},*/
  {
		name: 'Clients',
		layout: '/admin',
		path: '/clients',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Clients
	},
	{
		name: 'Forfaits',
		layout: '/admin',
		path: '/forfaits',
		icon: <Icon as={MdOutlineInsertDriveFile} width='20px' height='20px' color='inherit' />,
		component: Forfaits
	},
	{
		name: 'Contacts',
		layout: '/admin',
		path: '/contacts',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Contacts
	},
	{
		name: 'Interventions',
		layout: '/admin',
		path: '/interventions',
		icon: <Icon as={MdOutlineInsertDriveFile} width='20px' height='20px' color='inherit' />,
		component: Interventions
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	}
];

export default routes;
