import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useForfait } from "../../hooks/useForfait";
import { useContact } from "../../hooks/useContact";
import { useIntervention } from "../../hooks/useIntervention";
import { addDoc, collection, getDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { numberToHour } from "utils";


interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  intervention: InterventionType;
  newdata: (data: InterventionType[]) => void;
}

function ModalEmail(props: ModalComponentProps): JSX.Element {
  const { getForfaitField } = useForfait();
  const { getMainContactByClient, getEmailContactBccByClient } = useContact();
  const { fetch } = useIntervention();
  const { getInterventionsByIdForfait, updateInterventionField } =
    useIntervention();
  const { intervention } = props;
  const [mainContact, setMainContact] = useState<ContactType>();
  const [bccContact, setBccContact] = useState([""]);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [remarque, setRemarque] = useState("");
  const [isSending, setIsSending] = useState(false);
  const handleChangeRemarque = (event: ChangeEvent<HTMLInputElement>) =>
    setRemarque(event.target.value);
  let plugins = "";
  let sauvegarde = "";
  if (intervention.plugins) {
    plugins = " + plugins";
  }
  if (intervention.sauvegarde) {
    sauvegarde = " + sauvegarde";
  }
  useEffect(() => {
    const fetchContact = async () => {
      try {
        const currentClient: string = await getForfaitField(
          intervention.idforfait,
          "idclient"
        );
        if (currentClient) {
          const mainContact = await getMainContactByClient(currentClient);
          setMainContact(mainContact);
          const bccContact = await getEmailContactBccByClient(currentClient);
          setBccContact(bccContact);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchInterventions = async () => {
      try {
        const fetchedInterventions: InterventionType[] =
          await getInterventionsByIdForfait(intervention.idforfait);
        if (fetchedInterventions) {
          const temps = await getForfaitField(intervention.idforfait, "temps");
          if (temps) {
            const somme = fetchedInterventions.reduce(
              (acc, obj) => acc + obj.temps_passe,
              0
            );
            const time = parseFloat(temps) - somme;
            setTimeRemaining(numberToHour(time));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchContact();
    fetchInterventions();
  }, [intervention]);

  async function handleSubmit() {
    setIsSending(true);
    try {
      if (mainContact) {        
        const values = {
          to: mainContact.email,
          cc: bccContact,
          bcc: "contact@mri-freelance.com",
          template: {
            name: "maintenance",
            data: {
              prenom: mainContact.prenom,
              site_internet: intervention.site_internet,
              cms: intervention.cms,
              plugins: plugins,
              sauvegarde: sauvegarde,
              temps_passe: numberToHour(intervention.temps_passe),
              temps_restant: timeRemaining,
              remarque: remarque,
            },
          },
        };
        const docRef = await addDoc(collection(db, "email"), values);
        let attempts = 0;
        const maxAttempts = 4;
        const checkForUpdates = async () => {
          if (attempts < maxAttempts) {
            const updatedDoc = await getDoc(docRef);
            if (
              updatedDoc.exists() &&
              updatedDoc.data().delivery &&
              updatedDoc.data().delivery.state === "SUCCESS"
            ) {
              await updateInterventionField(
                intervention.id,
                "envoi_email",
                true
              );
              setIsSending(false);
              props.onClose();
              const newData: InterventionType[] = await fetch();
              props.newdata(newData);
            } else {
              attempts++;
              setTimeout(checkForUpdates, 2000);
            }
          } else {
            console.error("Nombre maximum de tentatives atteint sans succès.");
            setIsSending(false);
            props.onClose();
            const newData: InterventionType[] = await fetch();
            props.newdata(newData);
          }
        };
        checkForUpdates();
      }
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
    }
  }
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Envoi d'Email Intervention</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Voulez-vous envoyer cette intervention par email :</p>
          {mainContact && (
            <>
              <div style={{ padding: "20px 10px", margin: "10px 0" }}>
                <p>
                  {`Bonjour ${
                    mainContact.prenom
                  },</br>Je viens d'intervenir sur le site internet ${
                    intervention.site_internet
                  } : 
                  <p>Mise à jour ${
                    intervention.cms
                  } ${plugins} ${sauvegarde}</p><p>Temps passé : ${numberToHour(
                    intervention.temps_passe
                  )} </br>Temps restant : ${timeRemaining}</p>`}
                </p>
                <img
                  src="https://mri-freelance.com/signature.png"
                  width="200"
                  height="auto"
                />
                <p>
                  <strong>Jonas Millet</strong>
                  <br />
                  Développement web
                  <br />
                  Sites internet et Applications métier
                  <br />
                  06 16 48 75 17
                </p>
              </div>
            </>
          )}
          <>
            <Text mb="8px">Remarque:</Text>
            <Input
              value={remarque}
              onChange={handleChangeRemarque}
              placeholder="Remarque"
              size="sm"
            />
          </>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={() => handleSubmit()}>
            Envoyer
            {isSending && <Spinner size="sm" ml="4" />}
          </Button>
          <Button variant="ghost" onClick={props.onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalEmail;
