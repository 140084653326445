import {
  collection,
  getDocs,
  QuerySnapshot,
  DocumentData,
  deleteDoc,
  doc,
  query,
  where,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "firebaseConfig";

export function useForfait() {
  //refresh contact data
  const fetch = async (): Promise<ForfaitType[]> => {
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(
      collection(db, "forfait")
    );
    const rows: ForfaitType[] = [];

    querySnapshot.forEach((doc) => {
      const forfaitData = doc.data() as ForfaitType;
      const forfaitWithId = { id: doc.id, ...forfaitData };
      rows.push(forfaitWithId);
    });

    return rows;
  };

  // Get one field Contact
  const getForfaitById = async (
    idForfait: string
  ): Promise<ForfaitType[] | null> => {
    const docRef = doc(db, "forfait", idForfait);
    const docSnap = await getDoc(docRef);
    const row: ForfaitType[] = [];
    if (docSnap.exists()) {
      const forfaitData = docSnap.data() as ForfaitType;
      const forfaitWithId = { id: docSnap.id, ...forfaitData };
      row.push(forfaitWithId);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    return row;
  };

  //Get forfait status
  const getForfaitByClient = async (
    idClient: string
  ): Promise<ForfaitType[] | null> => {
    const q = query(
      collection(db, "forfait"),
      where("idclient", "==", idClient),
      where("statut", "==", true)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null; // Aucun contact trouvé
    }
    const rows: ForfaitType[] = [];

    querySnapshot.forEach((doc) => {
      const forfaitData = doc.data() as ForfaitType;
      const forfaitWithId = { id: doc.id, ...forfaitData };
      rows.push(forfaitWithId);
    });

    return rows;
  };
  //Get one field
  const getForfaitField = async (
    idForfait: string,
    field: string
  ): Promise<string | null> => {
    const docRef = doc(db, "forfait", idForfait);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data()[field];
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const getCurrentForfaits = async (): Promise<{ id: string; idclient: string }[] | null> => {
    const q = query(
      collection(db, "forfait"),
      where("type", "==", 1),
      where("statut", "==", true)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null; // Aucun contact trouvé
    }
    const rows: { id: string; idclient: string }[] = [];

    querySnapshot.forEach((doc) => {
      const forfaitData = doc.data() as ForfaitType
      const forfaitWithId = {id: doc.id, idclient:forfaitData['idclient']};
      rows.push(forfaitWithId);
    });
    
    return rows;
  };

  const getClientForfaits = async (idClient: string): Promise<{ id: string }[] | null> => {
    const q = query(
      collection(db, "forfait"),
      where("type", "==", 1),
      where("idclient", "==", idClient)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null; // Aucun contact trouvé
    }
    const rows: { id: string }[] = [];

    querySnapshot.forEach((doc) => {
      const forfaitWithId = {id: doc.id};
      rows.push(forfaitWithId);
    });
    
    return rows;
  };

  const updateForfaitField = async (
    idForfait: string,
    field: string,
    value:any
  ): Promise<void> => {
    const forfaitField = doc(db, "forfait", idForfait);
    // Set the "capital" field of the city 'DC'
    await updateDoc(forfaitField, {
      [field]: value
    });
  }

  return {
    fetch,
    getForfaitById,
    getForfaitByClient,
    getForfaitField,
    getCurrentForfaits,
    getClientForfaits,
    updateForfaitField,
  };
}
