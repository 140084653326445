import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Button,
  useDisclosure,
  Icon,
  Select,
} from "@chakra-ui/react";
import * as React from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import {
  MdAdd,
  MdCancel,
  MdHourglassBottom,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import ModalForm from "components/modal/modalForm";
import Moment from "react-moment";
import FieldClient from "components/user/FieldClient";
import { numberToHour } from "utils";
import ListMenuIntervention from "components/menu/ListMenuIntervention";
import { useClient } from "../../../../hooks/useClient";
import { useForfait } from "hooks/useForfait";
import { useIntervention } from "hooks/useIntervention";
import ModalEmail from "components/modal/modalEmail";
import { useLocation } from "react-router-dom";
import { log } from "console";
import ModalDelete from "components/modal/modalDelete";

type TableInterventionType = {
  id: string;
  date_intervention: any;
  cms: string;
  envoi_email: boolean;
  idforfait: string;
  plugins: boolean;
  sauvegarde: boolean;
  site_internet: string;
  temps_passe: number;
  client: string;
};

const columnHelper = createColumnHelper<TableInterventionType>();

// const columns = columnsDataCheck;
export default function ColumnTable(props: { tableData: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalDelete = useDisclosure();
  const modalEmail = useDisclosure();
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedIntervention, setSelectedIntervention] = React.useState<
    InterventionType[] | null
  >(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;

  const columns = [
    columnHelper.accessor("date_intervention", {
      id: "date_intervention",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Date
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            <Moment format="DD/MM/YYYY">{info.getValue().toDate()}</Moment>
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Client
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            <FieldClient
              id={info.row.original.idforfait}
              field="raison_sociale"
              context="intervention"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("temps_passe", {
      id: "temps_passe",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Temps
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Button
            leftIcon={<MdHourglassBottom />}
            colorScheme="teal"
            variant="solid"
            mr={4}
          >
            {numberToHour(info.getValue())}
          </Button>
        </Flex>
      ),
    }),
    columnHelper.accessor("site_internet", {
      id: "site_internet",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Site(s) Internet
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("cms", {
      id: "cms",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          CMS
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("plugins", {
      id: "plugin",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Plugin
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.row.original.plugins ? (
            <Icon
              as={MdOutlineCheckCircleOutline}
              color="green.500"
              w="24px"
              h="24px"
              me="5px"
            />
          ) : (
            <Icon as={MdCancel} color="red.500" w="24px" h="24px" me="5px" />
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor("sauvegarde", {
      id: "sauvegarde",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Sauvegarde
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.row.original.sauvegarde ? (
            <Icon
              as={MdOutlineCheckCircleOutline}
              color="green.500"
              w="24px"
              h="24px"
              me="5px"
            />
          ) : (
            <Icon as={MdCancel} color="red.500" w="24px" h="24px" me="5px" />
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor("envoi_email", {
      id: "envoi_email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Email client
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.row.original.envoi_email ? (
            <Icon
              as={MdOutlineCheckCircleOutline}
              color="green.500"
              w="24px"
              h="24px"
              me="5px"
            />
          ) : (
            <Icon as={MdCancel} color="red.500" w="24px" h="24px" me="5px" />
          )}
          <Spacer />
          <ListMenuIntervention
            interventionrow={[info.row.original]}
            openmodal={onOpen}
            setselectedintervention={setSelectedIntervention}
            opendeletemodal={modalDelete.onOpen}
            openemailmodal={modalEmail.onOpen}
          />
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const { getClients } = useClient();
  const { getClientForfaits } = useForfait();
  const { getInterventionsByForfaits } = useIntervention();
  const [clientsFilter, setClientsFilter] = React.useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  function handleAdd() {
    setSelectedIntervention(null);
    onOpen();
  }
  function handleChangeClient(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;

    const fetchForfaits = async (value: string) => {
      try {
        const data = await getClientForfaits(value);
        return data;
      } catch (error) {
        console.error(error);
      }
    };
    const fetchInterventions = async (idForfaits: { id: string }[]) => {
      try {
        const data = await getInterventionsByForfaits(idForfaits);
        return data;
      } catch (error) {
        console.error(error);
      }
    };
    const fetchData = async (value: string) => {
      try {
        const currentForfaits: { id: string }[] = await fetchForfaits(value); // Attendre que fetchForfaits soit résolu
        // Maintenant, vous pouvez utiliser currentForfaits pour effectuer d'autres opérations si nécessaire
        if (currentForfaits) {
          const currentInterventions = await fetchInterventions(
            currentForfaits
          );
          setData(currentInterventions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(value);
  }
  // Filter interventions by Forfaits
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const idForfait = query.get("idforfait");

  React.useEffect(() => {
    if (idForfait) {
      const filterInterventions = tableData.filter(
        (interventions: { idforfait: string; }) => interventions.idforfait === idForfait
      );
      setData(filterInterventions);
    }
  }, [idForfait]);
  // End Filter //
  React.useEffect(() => {
    table.setSorting([{ id: "date_intervention", desc: true }]);
  }, [table]);
  React.useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsData = await getClients();
        setClientsFilter(clientsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients :", error);
      }
    };
    fetchClients();
  }, [data]);
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          Liste des Interventions
        </Text>
        <Select
          placeholder="Selection client"
          width={450}
          onChange={handleChangeClient}
        >
          {clientsFilter.map((clientfilter) => {
            return (
              <option key={clientfilter.id} value={clientfilter.id}>
                {clientfilter.raison_sociale}
              </option>
            );
          })}
        </Select>
        <Button
          variant="darkBrand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          borderRadius="70px"
          px="24px"
          py="5px"
          leftIcon={<MdAdd />}
          onClick={handleAdd}
        >
          Ajouter une intervention
        </Button>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 100)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
      <ModalForm<InterventionType>
        isOpen={isOpen}
        onClose={onClose}
        newData={setData}
        updateData={selectedIntervention}
        form="intervention"
      />
      {selectedIntervention && (
        <ModalEmail
          isOpen={modalEmail.isOpen}
          onClose={modalEmail.onClose}
          intervention={selectedIntervention[0]}
          newdata={setData}
        />
      )}
      {selectedIntervention && (
        <ModalDelete
          isOpen={modalDelete.isOpen}
          onClose={modalDelete.onClose}
          idDelete={selectedIntervention[0].id}
          field={selectedIntervention[0].site_internet}
          type={"intervention"}
          newdata={setData}
        />
      )}
    </Card>
  );
}
