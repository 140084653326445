import { useEffect, useState } from "react";
import { useClient } from "../../hooks/useClient";
import { useForfait } from "../../hooks/useForfait";

interface FieldProps {
  id: string;
  field: string;
  context: string;
}

function FieldClient(props: FieldProps): JSX.Element {
  const { getClientField } = useClient();
  const { getForfaitField } = useForfait();
  const [field, setField] = useState("");
  const [idClient, setIdClient] = useState("");

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (idClient) {
        const fetchedClient = await getClientField(idClient, props.field);
        if (isMounted) {
          setField(fetchedClient);
        }
      }
    };

    if (props.context !== "intervention") {
      setIdClient(props.id);
    } else {
      const fetchDataForfait = async () => {
        const fetchedClient = await getForfaitField(props.id, "idclient");
        if (isMounted) {
          setIdClient(fetchedClient);
        }
      };

      fetchDataForfait();
    }

    fetchData(); // Appelez fetchData ici en dehors de la condition

    return () => {
      isMounted = false;
    };
  }, [props.id, props.field, props.context, idClient]);

  return <span>{field}</span>;
}

export default FieldClient;
