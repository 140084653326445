import { Controller, useForm } from "react-hook-form";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { db } from "firebaseConfig";
import { useForfait } from "hooks/useForfait";
import { useClient } from "hooks/useClient";
import { ChangeEvent, useEffect, useState } from "react";
import { useIntervention } from "hooks/useIntervention";
import UserIntervention from "components/user/UserIntervention";
import moment from "moment";

interface InterventionFormProps {
  onCloseModal: () => void;
  newData: (data: InterventionType[]) => void;
  updateData: InterventionType[];
}

export default function InterventionForm(props: InterventionFormProps) {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<InterventionType>();

  const [clients, setClients] = useState<ClientForfaitType[] | null>(null);
  const [selectedIdForfait, setSelectedIdForfait] = useState<{
    id: string;
    temps: number;
  }>();
  const { fetch } = useIntervention();
  const { getClientsByForfait } = useClient();
  const { getCurrentForfaits, getForfaitField } = useForfait();
  const [selectedClientForfait, setSelectedClientForfait] =
    useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentForfaits: { id: string; idclient: string }[] =
          await fetchForfaits(); // Attendre que fetchForfaits soit résolu
        // Maintenant, vous pouvez utiliser currentForfaits pour effectuer d'autres opérations si nécessaire
        if (currentForfaits) {
          const currentClients = await fetchClients(currentForfaits);
          setClients(currentClients);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchForfaits = async () => {
      try {
        const data = await getCurrentForfaits();
        return data; // Renvoyer les données pour les utiliser ultérieurement
      } catch (error) {
        console.error(error);
      }
    };

    const fetchClients = async (
      currentForfaits: { id: string; idclient: string }[]
    ) => {
      try {
        const data = await getClientsByForfait(currentForfaits);
        return data;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Appeler la fonction principale
    if(props.updateData?.[0]?.idforfait){
      setValue("idforfait",props.updateData[0].idforfait);
      setSelectedClientForfait(props.updateData[0].idforfait);
    }
  }, []);

  const handleSelectChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedClientForfait(event.target.value);
    setValue("idforfait", event.target.value);
    const tempsAsString = await getForfaitField(event.target.value, "temps");
    const temps = parseFloat(tempsAsString);
    setSelectedIdForfait({ id: event.target.value, temps: temps });
  };

  async function onSubmit(values: InterventionType) {
    try {
      const dateIntervention =
        typeof values.date_intervention === "string"
          ? Timestamp.fromDate(new Date(values.date_intervention))
          : values.date_intervention;

      const envoiEmail = Boolean(values.envoi_email);
      const plugins = Boolean(values.plugins);
      const sauvegarde = Boolean(values.sauvegarde);
      const newValues = {
        ...values,
        date_intervention: dateIntervention,
        envoi_email: envoiEmail,
        plugins: plugins,
        sauvegarde: sauvegarde,
      };
      if (props.updateData === null) {
        await addDoc(collection(db, "intervention"), newValues);
      } else {
        const updateForfait = doc(db, "intervention", props.updateData[0].id);
        await updateDoc(updateForfait, newValues);
      }
      props.onCloseModal();
      const newData: InterventionType[] = await fetch();
      props.newData(newData);
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
    }
  }
  const getUpdateDataValue = (field: keyof InterventionType) => {
    return props.updateData !== null ? props.updateData[0][field] : "";
  };

  const formatDateIntervention = () => {
    let dateIntervention;
    if(props.updateData){
      if (props.updateData[0].date_intervention instanceof Date) {
        dateIntervention = moment(props.updateData[0].date_intervention);
      } else {
        dateIntervention = moment(
          Number(props.updateData[0].date_intervention.seconds) * 1000 +
            Number(props.updateData[0].date_intervention.nanoseconds) / 1000000
        );
      }
      return dateIntervention.format("YYYY-MM-DDTHH:mm");
    }else{
      return "";
    }
  };

  const renderInput = (
    field: keyof InterventionType,
    placeholder: string,
    minLength: number,
    typeInput: string
  ) => {
    const defaultValue = getUpdateDataValue(field);
    const maxNumber = 30;
    const defaultValueForInput =
      typeInput === "datetime-local" ? formatDateIntervention() : defaultValue;

    if (typeInput === "select") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          {selectedIdForfait && (
            <Box mb={2}>
              <UserIntervention
                id={selectedIdForfait.id}
                temps={selectedIdForfait.temps}
              />
            </Box>
          )}
          <Controller
            name="idforfait"
            control={control}
            defaultValue={props.updateData?.[0]?.idforfait}
            render={({ field: { ref, value, onChange, ...field } }) => {
              return (
                <Select
                  placeholder="Client"
                  {...register("idforfait", {
                    required: "Champ obligatoire",
                    // ... autres règles de validation
                  })}
                  {...field}
                  ref={ref}
                  value={selectedClientForfait}
                  onChange={handleSelectChange}
                >
                  {/* Générez les options à partir des données des clients */}
                  {clients &&
                    clients.map((client) => (
                      <option key={client.idforfait} value={client.idforfait}>
                        {client.raison_sociale}
                      </option>
                    ))}
                </Select>
              );
            }}
          />
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    } else if (typeInput === "number") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <NumberInput
            defaultValue={Number(defaultValue)}
            max={maxNumber}
            clampValueOnBlur={false}
          >
            <NumberInputField
              id={field}
              {...register(field, {
                required: "Champ obligatoire",
                valueAsNumber: true,
                // ... autres règles de validation
              })}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    } else if (typeInput === "boolean") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <Checkbox
            name={field}
            {...register(field)}
            defaultChecked={defaultValue as boolean}
          >
            {field}
          </Checkbox>
        </FormControl>
      );
    } else {
      // Utilisez le bloc else pour gérer d'autres types d'entrées
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <Input
            id={field}
            placeholder={placeholder}
            {...register(field, {
              required: "Champ obligatoire",
              minLength: {
                value: minLength,
                message: `La longueur minimum est de ${minLength} caractères`,
              },
            })}
            defaultValue={defaultValueForInput as string}
            type={typeInput}
          />
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderInput(
        "date_intervention",
        "Date d'intervention",
        4,
        "datetime-local"
      )}
      {renderInput("idforfait", "Client", 4, "select")}
      {renderInput("temps_passe", "Temps", 2, "number")}
      {renderInput("site_internet", "Site internet", 4, "text")}
      {renderInput("cms", "CMS", 4, "text")}
      {renderInput("envoi_email", "Email", 1, "boolean")}
      {renderInput("plugins", "Plugins", 1, "boolean")}
      {renderInput("sauvegarde", "Sauvegarde", 1, "boolean")}

      <Button
        mt={4}
        colorScheme="teal"
        isLoading={isSubmitting}
        type="submit"
        mb={4}
      >
        {props.updateData !== null
          ? `Modifier l'intervention`
          : `Ajouter une intervention`}
      </Button>
    </form>
  );
}
