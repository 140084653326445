import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Button,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import * as React from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import { MdAdd, MdCancel, MdOutlineCheckCircleOutline } from "react-icons/md";
import ModalForm from "components/modal/modalForm";

import ModalDelete from "components/modal/modalDelete";
import FieldClient from "components/user/FieldClient";
import ListMenuContact from "components/menu/ListMenuContact";


type TableContactType = {
  id: string;
  email: string;
  nom: string;
  prenom: string;
  idclient: string;
  principal: boolean;
};

const columnHelper = createColumnHelper<TableContactType>();

// const columns = columnsDataCheck;
export default function ColumnTable(props: { tableData: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalDelete = useDisclosure();
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedContact, setSelectedContact] = React.useState<
    ContactType[] | null
  >(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("idclient", {
      id: "idclient",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Client
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            <FieldClient
              id={info.row.original.idclient}
              field="raison_sociale"
              context="contact"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Email
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("principal", {
      id: "principal",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Principal
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          {info.row.original.principal ? <Icon as={MdOutlineCheckCircleOutline} color="green.500" w="24px" h="24px" me="5px" /> : <Icon as={MdCancel} color="red.500" w="24px" h="24px" me="5px" />}
        </Flex>
      ),
    }),
    columnHelper.accessor("nom", {
      id: "nom",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Nom
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("prenom", {
      id: "prenom",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Prénom
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
          <Spacer />
          <ListMenuContact
            contactrow={[info.row.original]}
            openmodal={onOpen}
            setselectedcontact={setSelectedContact}
            opendeletemodal={modalDelete.onOpen}
          />
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  function handleAdd() {
    setSelectedContact(null);
    onOpen();
  }
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          Liste des contacts
        </Text>
        <Button
          variant="darkBrand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          borderRadius="70px"
          px="24px"
          py="5px"
          leftIcon={<MdAdd />}
          onClick={handleAdd}
        >
          Ajouter un contact
        </Button>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 100)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
      <ModalForm<ContactType>
        isOpen={isOpen}
        onClose={onClose}
        newData={setData}
        updateData={selectedContact}
        form="contact"
      />
      {selectedContact && (
        <ModalDelete
          isOpen={modalDelete.isOpen}
          onClose={modalDelete.onClose}
          idDelete={selectedContact[0].id}
          field={selectedContact[0].email}
          type={"contact"}
          newdata={setData}
        />
      )}
    </Card>
  );
}
