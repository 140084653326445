import { Controller, useForm } from "react-hook-form";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spacer,
  RadioGroup,
  Stack,
  Radio,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import { db } from "firebaseConfig";
import { useContact } from "hooks/useContact";
import { useEffect, useState } from "react";
import { useClient } from '../../hooks/useClient';

interface ContactFormProps {
  onCloseModal: () => void;
  newData: (data: ContactType[]) => void;
  updateData: ContactType[] | null;
}

export default function ContactForm(props: ContactFormProps) {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ContactType>();

  const { getContacts, getOne } = useContact();
  const { getClients } = useClient();
  const [selectedClient, setSelectedClient] = useState<string>('')

  const [clients, setClients] = useState<ClientType[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getClients();
        setClients(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    //init idclient if update contact
    if(props.updateData?.[0]?.idclient){
      setValue("idclient",props.updateData[0].idclient);
      setSelectedClient(props.updateData[0].idclient);
    }
  }, []);

  async function onSubmit(values: ContactType) {
    try {
      if (props.updateData === null) {
        const principal = Boolean(values.principal);
        const newValues = { ...values, principal: principal };
        await addDoc(collection(db, "contact"), newValues);
      } else {
        const updateClient = doc(db, "contact", props.updateData[0].id);
        await updateDoc(updateClient, values);
      }
      props.onCloseModal();
      const renewData: ContactType[] = await getContacts();
      props.newData(renewData);
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
    }
  }
  const getUpdateDataValue = (field: keyof ContactType) => {
    return props.updateData !== null ? props.updateData[0][field] : "";
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.idclient} mb={4}>
        <FormLabel htmlFor="idclient">Client</FormLabel>
        <Controller
          name="idclient"
          control={control}
          defaultValue={props.updateData?.[0]?.idclient}
          render={({ field: { ref, value, onChange, ...field } }) => {
            return (
            <Select
              id="idclient"
              placeholder="Client"
              {...register("idclient", {
                required: "Champ obligatoire",
                // ... autres règles de validation
              })}
              {...field}
              ref={ref}
              value={selectedClient}
              onChange={(event) => {
                setSelectedClient(event.target.value);
                setValue('idclient',event.target.value);
              }}
            >
              {/* Générez les options à partir des données des clients */}
              {clients &&
                clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.raison_sociale}
                  </option>
                ))}
            </Select>
          );
        }}
        />
        <FormErrorMessage>
          {errors.idclient && errors.idclient.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.email} mb={4}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          id="email"
          placeholder="Email"
          {...register("email", {
            required: "Champ obligatoire",
            minLength: {
              value: 4,
              message: "La longueur minimum est de 4 caractères",
            },
          })}
          type="email"
          defaultValue={props.updateData?.[0]?.email}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.nom} mb={4}>
        <FormLabel htmlFor="nom">Nom</FormLabel>
        <Input
          id="nom"
          placeholder="Nom"
          {...register("nom", {
            required: "Champ obligatoire",
            minLength: {
              value: 4,
              message: "La longueur minimum est de 4 caractères",
            },
          })}
          defaultValue={props.updateData?.[0]?.nom}
        />
        <FormErrorMessage>{errors.nom && errors.nom.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.prenom} mb={4}>
        <FormLabel htmlFor="prenom">Prénom</FormLabel>
        <Input
          id="prenom"
          placeholder="Prénom"
          {...register("prenom", {
            required: "Champ obligatoire",
            minLength: {
              value: 4,
              message: "La longueur minimum est de 4 caractères",
            },
          })}
          defaultValue={props.updateData?.[0]?.prenom}
        />
        <FormErrorMessage>
          {errors.prenom && errors.prenom.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.principal} mb={4}>
        <Checkbox
          name="principal"
          {...register("principal")}
          isChecked={props.updateData?.[0]?.principal}
        >
          Contact principal
        </Checkbox>
      </FormControl>

      <Button
        mt={4}
        colorScheme="teal"
        isLoading={isSubmitting}
        type="submit"
        mb={4}
      >
        {props.updateData !== null
          ? `Modifier le contact`
          : `Ajouter le contact`}
      </Button>
    </form>
  );
}
