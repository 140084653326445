import {
  collection,
  getDocs,
  QuerySnapshot,
  DocumentData,
  deleteDoc,
  doc,
  query,
  where,
  getDoc,
  documentId,
  updateDoc,
} from "firebase/firestore";
import { db } from "firebaseConfig";

export function useIntervention() {
  //refresh intervention data
  const fetch = async (): Promise<InterventionType[]> => {
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(
      collection(db, "intervention")
    );
    const rows: InterventionType[] = [];

    querySnapshot.forEach((doc) => {
      const interventionData = doc.data() as InterventionType;
      const interventionWithId = { id: doc.id, ...interventionData };
      rows.push(interventionWithId);
    });

    return rows;
  };

  // Get one intervention
  const getOne = async (id: string): Promise<InterventionType | null> => {
    const docRef = doc(db, "intervention", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const contactData = docSnap.data() as InterventionType;
      const contactWithId = { id: docSnap.id, ...contactData };
      return contactWithId;
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      return null;
    }
  };

  //Get one field
  const getField = async (
    idIntervention: string,
    field: string
  ): Promise<string | null> => {
    const docRef = doc(db, "intervention", idIntervention);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data()[field];
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const getInterventionsByIdForfait = async (
    idForfait: string
  ): Promise<InterventionType[] | null> => {
    const q = query(
      collection(db, "intervention"),
      where("idforfait", "==", idForfait)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null; // Aucun contact trouvé
    }
    const rows: InterventionType[] = [];

    querySnapshot.forEach((doc) => {
      const interventionData = doc.data() as InterventionType;
      const interventionWithId = { id: doc.id, ...interventionData };
      rows.push(interventionWithId);
    });
    return rows;
  };

  //Get Interventins with forfaits
  const getInterventionsByForfaits = async (
    idForfaits: { id: string }[]
  ): Promise<InterventionType[]> => {
    const idForfaitsArray: string[] = idForfaits.map((item) => item.id);
    const q = query(
      collection(db, "intervention"),
      where("idforfait", "in", idForfaitsArray)
    );
    const querySnapshot = await getDocs(q);
    const rows: InterventionType[] = [];

    querySnapshot.forEach((doc) => {
      const interventionData = doc.data() as InterventionType;
      const interventionWithId = { id: doc.id, ...interventionData };
      rows.push(interventionWithId);
    });
    return rows;
  };

  const updateInterventionField = async (
    idIntervention: string,
    field: string,
    value: any
  ): Promise<void> => {
    const interventionField = doc(db, "intervention", idIntervention);
    await updateDoc(interventionField, {
      [field]: value,
    });
  };

  //delete intervention by id
  const deleteIntervention = async (idIntervention: string) => {
    await deleteDoc(doc(db, "intervention", idIntervention));
  };

  return {
    fetch,
    getOne,
    getField,
    getInterventionsByIdForfait,
    getInterventionsByForfaits,
    updateInterventionField,
    deleteIntervention,
  };
}
