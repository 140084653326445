import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { UserAuthContextProvider } from 'auth/auth';
import ProtectedRoute from 'auth/protected-route';

function AppRoutes() {
  return (
	<ChakraProvider theme={theme}>
		<React.StrictMode>
     <UserAuthContextProvider>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <ProtectedRoute path="/admin">
              <AdminLayout />
            </ProtectedRoute>
            <Redirect from='/' to='/auth' />
          </Switch>
        </HashRouter>
      </UserAuthContextProvider>
		</React.StrictMode>
	</ChakraProvider>
  );
}

ReactDOM.render(<AppRoutes />, document.getElementById('root'));