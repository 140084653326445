import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useClient } from "../../hooks/useClient";
import { useContact } from "../../hooks/useContact";
import { useIntervention } from "../../hooks/useIntervention";

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  idDelete: string;
  field: string;
  type: string;
  newdata: (data: ClientType[] | ContactType[] | InterventionType[]) => void;
}

function ModalDelete(props: ModalComponentProps): JSX.Element {
  const { getClients, deleteClient } = useClient();
  const { getContacts, deleteContact } = useContact();
  const { fetch, deleteIntervention } = useIntervention();

  async function handleDelete(id: string) {
    try {
      let newData: ClientType[] | ContactType[] | InterventionType[];
      if (props.type === "client") {
        await deleteClient(id);
        newData = await getClients();
      } else if (props.type === "contact") {
        await deleteContact(id);
        newData = await getContacts();
      } else if (props.type === "intervention") {
        await deleteIntervention(id);
        newData = await fetch();
      }
      props.onClose();
      props.newdata(newData);
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation de suppression</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.type === "client" && (
            <span>Voulez-vous supprimer le client : {props.field}</span>
          )}
          {props.type === "contact" && (
            <span>Voulez-vous supprimer le contact : {props.field}</span>
          )}
          {props.type === "intervention" && (
            <span>
              Voulez-vous supprimer l'intervention sur : {props.field}
            </span>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => handleDelete(props.idDelete)}
          >
            Supprimer
          </Button>
          <Button variant="ghost" onClick={props.onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalDelete;
