import {
  collection,
  getDocs,
  QuerySnapshot,
  DocumentData,
  deleteDoc,
  doc,
  query,
  where,
  getDoc,
  documentId,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "firebaseConfig";

export function useClient() {
  const getClients =  async (): Promise<ClientType[]> =>{
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(
      collection(db, "client")
    );
    const rows: ClientType[] = [];
  
    querySnapshot.forEach((doc) => {
      const clientData = doc.data() as ClientType;
      const clientWithId = { id: doc.id, ...clientData };
      rows.push(clientWithId);
    });
  
    return rows;
  }
  
  //Get one field Client
  const getClientField =  async (
    idClient: string,
    field: string
  ): Promise<string | null> => {
    const docRef = doc(db, "client", idClient);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      return docSnap.data()[field];
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  //Get Clients with forfaits
  const getClientsByForfait =  async (
    idForfaits: { id: string; idclient: string }[]
  ): Promise<ClientForfaitType[]> => {
    
    const idclientArray: string[] = idForfaits.map(item => item.idclient);
    const q = query(
      collection(db, "client"),
      where(documentId(), "in",
      idclientArray
      ),
    );
    const querySnapshot = await getDocs(q);
    const rows: ClientForfaitType[] = [];
    const docsArray = querySnapshot.docs;

    docsArray.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      const clientData = doc.data() as ClientType;
      const idclient = doc.id;
      const matchingForfait = idForfaits.find((forfait) => forfait.idclient === idclient);
      if (matchingForfait) {
        const idforfait = matchingForfait.id;
        const clientWithId = { id: idclient, idforfait, ...clientData };
        rows.push(clientWithId);
      }
    });
    return rows;
  }
  
  //delete client by id
  const deleteClient = async (idClient: string) =>{
    await deleteDoc(doc(db, "client", idClient));
  }

  return {
    getClients,
    getClientField,
    getClientsByForfait,
    deleteClient,
  };
}