import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClientForm from "components/forms/clientForm";
import ContactForm from "components/forms/ContactForm";
import ForfaitForm from "components/forms/forfaitForm";
import InterventionForfaitForm from "components/forms/interventionForfaitForm";
import InterventionForm from "components/forms/interventionForm";

interface ModalComponentProps<T> {
  isOpen: boolean;
  onClose: () => void;
  newData: (data: T[]) => void;
  updateData?: ClientType[] | ForfaitType[] | ContactType[] | InterventionType[] ;
  form: string;
}

function ModalForm<T>(props: ModalComponentProps<T>): JSX.Element {
  function closeModal() {
    props.onClose();
  }
  return (
    <>
      <Modal isOpen={props.isOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {props.form === "client" && <span>Client</span>}
            {props.form === "forfait" && <span>Forfait</span>}
            {props.form === "contact" && <span>Contact</span>}
            {props.form === "intervention" && <span>Intervention</span>}
            {props.form === "interventionForfait" && <span>Intervention</span>}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.form === "client" && (
              <ClientForm
                onCloseModal={closeModal}
                newData={props.newData as (data: ClientType[]) => void}
                updateData={props.updateData as ClientType[]}
              />
            )}
            {props.form === "forfait" && (
              <ForfaitForm
                onCloseModal={closeModal}
                newData={props.newData as (data: ForfaitType[]) => void}
                updateData={props.updateData as ForfaitType[]}
              />
            )}
            {props.form === "contact" && (
              <ContactForm
                onCloseModal={closeModal}
                newData={props.newData as (data: ContactType[]) => void}
                updateData={props.updateData as ContactType[]}
              />
            )}
            {props.form === "intervention" && (
              <InterventionForm
                onCloseModal={closeModal}
                newData={props.newData as (data: InterventionType[]) => void}
                updateData={props.updateData as InterventionType[]}
              />
            )}
            {props.form === "interventionForfait" && (
              <InterventionForfaitForm
                onCloseModal={closeModal}
                newData={props.newData as (data: ForfaitType[]) => void}
                updateData={props.updateData as ForfaitType[]}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalForm;
