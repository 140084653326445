import { useForm } from "react-hook-form";
import {
  collection,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  Box,
  Text,
} from "@chakra-ui/react";
import { db } from "firebaseConfig";
import { useForfait } from "hooks/useForfait";
import { useClient } from "hooks/useClient";
import { useEffect, useState } from "react";
import UserIntervention from "components/user/UserIntervention";

interface InterventionForfaitFormProps {
  onCloseModal: () => void;
  newData: (data: ForfaitType[]) => void;
  updateData: ForfaitType[];
}

export default function InterventionForfaitForm(
  props: InterventionForfaitFormProps
) {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<InterventionType>();

  const [selectedIdForfait, setSelectedIdForfait] = useState<{
    id: string;
    temps: number;
  }>();
  const { fetch } = useForfait();
  const { getClientField } = useClient();
  const { getForfaitField } = useForfait();
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const client = await getClientField(
          props.updateData[0].idclient,
          "raison_sociale"
        );
        setClientName(client);
        setValue("idforfait", props.updateData[0].id);
        const tempsAsString = await getForfaitField(
          props.updateData[0].id,
          "temps"
        );
        const temps = parseFloat(tempsAsString);
        setSelectedIdForfait({ id: props.updateData[0].id, temps: temps });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  async function onSubmit(values: InterventionType) {
    try {
      const dateIntervention =
        typeof values.date_intervention === "string"
          ? Timestamp.fromDate(new Date(values.date_intervention))
          : values.date_intervention;

      const envoiEmail = Boolean(values.envoi_email);
      const plugins = Boolean(values.plugins);
      const sauvegarde = Boolean(values.sauvegarde);
      const newValues = {
        ...values,
        date_intervention: dateIntervention,
        envoi_email: envoiEmail,
        plugins: plugins,
        sauvegarde: sauvegarde,
      };
      if (props.updateData[0].id) {
        await addDoc(collection(db, "intervention"), newValues);
      }
      props.onCloseModal();
      const newData: ForfaitType[] = await fetch();
      props.newData(newData);
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
    }
  }

  const renderInput = (
    field: keyof InterventionType,
    placeholder: string,
    minLength: number,
    typeInput: string
  ) => {
    const maxNumber = 30;
    if (selectedIdForfait && typeInput === "client") {
      return (
        <>
          <Text>{clientName}</Text>
          <Box mb={2}>
            <UserIntervention
              id={selectedIdForfait.id}
              temps={selectedIdForfait.temps}
            />
          </Box>
        </>
      );
    } else if (typeInput === "number") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <NumberInput max={maxNumber} clampValueOnBlur={false}>
            <NumberInputField
              id={field}
              {...register(field, {
                required: "Champ obligatoire",
                valueAsNumber: true,
                // ... autres règles de validation
              })}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    } else if (typeInput === "boolean") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <Checkbox name={field} {...register(field)}>
            {field}
          </Checkbox>
        </FormControl>
      );
    } else {
      // Utilisez le bloc else pour gérer d'autres types d'entrées
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <Input
            id={field}
            placeholder={placeholder}
            {...register(field, {
              required: "Champ obligatoire",
              minLength: {
                value: minLength,
                message: `La longueur minimum est de ${minLength} caractères`,
              },
            })}
            type={typeInput}
          />
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderInput(
        "date_intervention",
        "Date d'intervention",
        4,
        "datetime-local"
      )}
      {renderInput("idforfait", "Client", 4, "client")}
      {renderInput("temps_passe", "Temps", 2, "number")}
      {renderInput("site_internet", "Site internet", 4, "text")}
      {renderInput("cms", "CMS", 4, "text")}
      {renderInput("envoi_email", "Email", 1, "boolean")}
      {renderInput("plugins", "Plugins", 1, "boolean")}
      {renderInput("sauvegarde", "Sauvegarde", 1, "boolean")}

      <Button
        mt={4}
        colorScheme="teal"
        isLoading={isSubmitting}
        type="submit"
        mb={4}
      >
        Ajouter une intervention
      </Button>
    </form>
  );
}
