import {
  collection,
  getDocs,
  QuerySnapshot,
  DocumentData,
  deleteDoc,
  doc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "firebaseConfig";

export function useContact() {
  //refresh contact data
  const getContacts = async (): Promise<ContactType[]> => {
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(
      collection(db, "contact")
    );
    const rows: ContactType[] = [];

    querySnapshot.forEach((doc) => {
      const contactData = doc.data() as ContactType;
      const contactWithId = { id: doc.id, ...contactData };
      rows.push(contactWithId);
    });
    
    return rows;
    
  }

  // Get one field Contact
  const getOne = async (id: string): Promise<ContactType | null> => {
    const docRef = doc(db, "contact", id); 
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const contactData = docSnap.data() as ContactType;
      const contactWithId = { id: docSnap.id, ...contactData };
      return contactWithId;
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      return null; 
    }
  }

  //Get main contact by client
  const getMainContactByClient =  async (idClient: string): Promise<ContactType | null> => {
    const q = query(
      collection(db, "contact"),
      where("idclient", "==", idClient),
      where("principal", "==", true)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null;
    }
    const contact = querySnapshot.docs[0].data() as ContactType;
    return contact;
  }

  //Get other contacts by client
  const getEmailContactBccByClient =  async (idClient: string): Promise<string [] | null> => {
    const q = query(
      collection(db, "contact"),
      where("idclient", "==", idClient),
      where("principal", "==", false)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null;
    }
    let contactEmails = [] as string[];
    querySnapshot.forEach((doc) => {
      const contactEmail = doc.data().email;
      if (contactEmail) { 
        contactEmails.push(contactEmail);
      }
    });
    return contactEmails;
  }

  //Get main contact email
  const getMainContact =  async (idClient: string): Promise<string | null> => {
    const q = query(
      collection(db, "contact"),
      where("idclient", "==", idClient),
      where("principal", "==", true)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return null;
    }
    const contact = querySnapshot.docs[0].data();
    const email = contact.email; 
    return email;
  }

  //delete contact by id
  const deleteContact = async (idContact: string) =>{
    await deleteDoc(doc(db, "contact", idContact));
  }

  return {
    getContacts,
    getOne,
    getMainContact,
    getMainContactByClient,
    getEmailContactBccByClient,
    deleteContact,
  };
}