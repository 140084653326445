import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  UserCredential
} from "firebase/auth";
import { auth } from '../firebaseConfig';

interface User {
  uid: string;
  email: string;
}

interface UserAuthContextProps {
  user: User;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => Promise<void>;
}

const userAuthContext = createContext<UserAuthContextProps | null>(null);

export function UserAuthContextProvider({ children }: { children: ReactNode })  {
  const [user, setUser] = useState<User | null>(null);

  function logIn(email:string, password:string) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, logOut }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
