import { Button, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdCalendarMonth, MdHourglassBottom } from "react-icons/md";
import Moment from "react-moment";
import { numberToHour } from "utils";
import { useIntervention } from "hooks/useIntervention";

interface InterventionProps {
  id: string;
  temps: number;
}

function UserIntervention(props: InterventionProps): JSX.Element {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [lastInter, setLastInter] = useState<any>(null);
  const [warningTime, setWarningTime] = useState("teal");
  const { getInterventionsByIdForfait } = useIntervention();
  useEffect(() => {
    let isMounted = true; // Variable de suivi pour vérifier si le composant est monté
    const fetchData = async () => {
      const fetchedInterventions = await getInterventionsByIdForfait(props.id);
      if (isMounted) {
        if (fetchedInterventions) {
          const somme = fetchedInterventions.reduce(
            (acc, obj) => acc + obj.temps_passe,
            0
          );
          const time = props.temps - somme;

          if (time === 0) {
            setWarningTime("red");
          } else if (time <= 1) {
            setWarningTime("orange");
          } else {
            setWarningTime("teal");
          }

          setTimeRemaining(numberToHour(time));

          const tableData = fetchedInterventions.sort(
            (a: any, b: any) =>
              b.date_intervention.seconds - a.date_intervention.seconds
          );
          let lastData;
          if (tableData[0].date_intervention instanceof Date) {
            lastData = tableData[0].date_intervention;
          } else {
            lastData = new Date(
              Number(tableData[0].date_intervention.seconds) * 1000 +
                Number(tableData[0].date_intervention.nanoseconds) / 1000000
            );
          }

          setLastInter(lastData);
        } else {
          setTimeRemaining(numberToHour(props.temps));
          setLastInter(null);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false; // Définir la variable isMounted sur false lors du démontage du composant
    };
  }, [props.id]);

  return (
    <>
      <Button
        leftIcon={<MdHourglassBottom />}
        colorScheme={warningTime}
        variant="solid"
        mr={4}
      >
        {timeRemaining}
      </Button>
      {lastInter && (
        <Button
          rightIcon={<MdCalendarMonth />}
          colorScheme="teal"
          variant="outline"
        >
          {lastInter && <Moment format="DD/MM/YYYY">{lastInter}</Moment>}
        </Button>
      )}
    </>
  );
}

export default UserIntervention;
