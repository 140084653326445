// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import {
	TbCircleLetterM
} from 'react-icons/tb';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Text><TbCircleLetterM fontSize='100px' /></Text>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
