import moment from "moment";

export function numberToHour(time:number) {
  const hours = Math.floor(time);
  const minutes = Math.round((time % 1)*100)/100;
  const formatHour = moment(hours + 'h' + minutes * 60 ,"LT");
  return formatHour.format('HH:mm');
}

export function dateToSeconds(date:any){
  const dateObj = new Date(date);
  const seconds = Math.floor(dateObj.getTime() / 1000); // Convertir en secondes
  const nanoseconds = (dateObj.getTime() % 1000) * 1000000; // Convertir en nanosecondes
  const result = { seconds, nanoseconds };
  return result;
}