import { Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { useForfait } from '../../hooks/useForfait';
import UserIntervention from "./UserIntervention";

interface ForfaitProps {
  id: string;
  type: string;
}

function ForfaitStatus(props: ForfaitProps): JSX.Element {
  const [status, setStatus] = useState(false);
  const [idForfait, setIdForfait] = useState("");
  const [temps, setTemps] = useState(null);
  const [type, setType] = useState(null);
  const { getForfaitById, getForfaitByClient } = useForfait();
  useEffect(() => {
    let isMounted = true; // Variable de suivi pour vérifier si le composant est monté
    const fetchData = async () => {
      let fetchedForfait: ForfaitType[] = [];
      if (props.type === "client") {
        fetchedForfait = await getForfaitByClient(props.id);
      } else {
        fetchedForfait = await getForfaitById(props.id);
      }

      if (isMounted && fetchedForfait) {
        setStatus(fetchedForfait[0].statut);
        setIdForfait(fetchedForfait[0].id);
        setTemps(fetchedForfait[0].temps);
        setType(fetchedForfait[0].type);
      }
    };
    fetchData();
    return () => {
      isMounted = false; // Définir la variable isMounted sur false lors du démontage du composant
    };
  }, [props.id, temps]);
  return (
    <span>
      {!status && props.type === "client" && (
        <Icon as={MdCancel} color="red.500" w="24px" h="24px" me="5px" />
      )}
      {type === 1 && <UserIntervention id={idForfait} temps={temps} />}
      {type === 2 && (
        <Text
          color={status === true ? "teal" : "red.400"}
          fontSize="sm"
          fontWeight="700"
        >
          ANNUEL
        </Text>
      )}
    </span>
  );
}

export default ForfaitStatus;
